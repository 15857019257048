<template>
  <div class="interview">
    <div class="container">
      <div class="interview__nav">
        <locale-router-link to="/" class="crumbs-link main-page-link">
          {{ $t("home") }}</locale-router-link
        >
        <a class="crumbs-link interview-link">{{ $t("survey") }}</a>
      </div>

      <h3 class="interview__title">{{ $t("takeSurvey") }}</h3>
      <div class="interview__content">
        <validation-observer ref="formInterview">
          <form class="interview__form">
            <div class="interview__item">
              <h6 class="interview__item-title">
                {{ $t("interview.skuName.title") }}
              </h6>
            </div>
            <div v-if="cities.length" class="interview__item">
              <h6 class="interview__item-title">
                {{ $t("interview.city.title") }}
              </h6>
              <div class="interview__select">
                <div
                  class="interview__select-value"
                  :class="{ 'not-filled': isCityValidate }"
                  @click.stop="isShowCities = !isShowCities"
                >
                  {{
                    selectCity
                      ? selectCity.attributes.name
                      : $t("interview.city.input")
                  }}
                </div>
                <div
                  v-if="isShowCities"
                  class="interview__select-dropdown"
                  style="z-index: 101"
                >
                  <ul class="interview__select-list">
                    <li
                      v-for="(city, index) in cities"
                      :key="index"
                      @click="getSelectCity(city)"
                      :class="{
                        'is-select': selectCity
                          ? city.id === selectCity.id
                          : false,
                      }"
                    >
                      {{ city.attributes.name }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div v-if="shopsForApi.length" class="interview__item">
              <h6 class="interview__item-title">
                {{ $t("interview.shop.title") }}
              </h6>
              <div v-if="!isShopsLoader" class="interview__select">
                <div class="interview__select-textarea">
                  <textarea
                    :class="{ 'not-filled': isShopsValidate }"
                    type="text"
                    :placeholder="$t('interview.shop.input')"
                    @click.stop="
                      isShowShops = !isShowShops;
                      shopAddress = '';
                    "
                    v-model="shopAddress"
                  />
                </div>

                <div
                  v-if="isShowShops"
                  class="interview__select-dropdown"
                  :class="{ active: isShowShops }"
                >
                  <ul class="interview__select-list">
                    <li
                      v-for="(item, index) in shopsData"
                      :key="index"
                      @click="selectShop(item)"
                    >
                      {{ item.attributes.name + " " + item.attributes.address }}
                    </li>
                  </ul>
                </div>
              </div>
              <div v-else class="loader-mini__container">
                <div class="loader-mini"></div>
              </div>
            </div>
            <div v-else class="loader-mini__container">
              <div class="loader-mini"></div>
            </div>
            <div class="interview__item">
              <validation-provider v-slot="{ errors }" rules="required">
                <h6
                  class="interview__item-title"
                  :class="{ 'not-filled': errors[0] === 'required' }"
                >
                  {{ $t("interview.skuDescription.title") }}
                </h6>
                <textarea
                  class="interview__item-textarea"
                  :placeholder="$t('interview.skuDescription.input')"
                  v-model="interview.sku_description"
                ></textarea>
              </validation-provider>
            </div>
            <div class="interview__item">
              <h6
                class="interview__item-title"
                :class="{ 'not-filled': isCategoriesValidate }"
              >
                {{ $t("interview.categories.title") }}
              </h6>
              <div v-if="checkboxes.length" class="interview__item-checkboxes">
                <div
                  v-for="(checkbox, index) in checkboxes"
                  :key="index"
                  class="interview__item-checkbox"
                >
                  <input
                    class="interview__item-check"
                    type="checkbox"
                    :id="checkbox.id"
                    :checked="interview.categories.includes(checkbox.id)"
                    @change="selectCategories(checkbox.id)"
                  />
                  <label :for="checkbox.id">
                    {{ checkbox.attributes.label }}
                  </label>
                </div>
              </div>
              <div class="interview__item-checkbox">
                <input
                  class="interview__item-check"
                  type="checkbox"
                  id="other"
                  v-model="showOther"
                />
                <label for="other"
                  >{{ $t("interview.categories.other") }}
                </label>
              </div>
              <input
                v-if="showOther"
                class="interview__item-input other"
                type="text"
                v-model="interview.other"
                :placeholder="$t('interview.categories.input')"
              />
            </div>
            <div class="interview__item">
              <h5 class="interview__item-title">
                {{ $t("interview.userInfo.title") }}
              </h5>
            </div>
            <div class="interview__item">
              <h6 class="interview__item-title">
                {{ $t("interview.userInfo.phone.title") }}
              </h6>
              <input
                class="interview__item-input"
                type="text"
                :placeholder="$t('interview.userInfo.phone.input')"
                v-model="interview.phone"
                v-mask="'+#(###)###-##-##'"
              />
            </div>
            <div class="interview__item">
              <h6 class="interview__item-title">E-mail</h6>
              <validation-provider v-slot="{ errors }" rules="email">
                <span class="full-vacancy__span">{{
                  errors[0] === "email" ? $t("emailIncorrectly") : ""
                }}</span>
                <input
                  class="interview__item-input"
                  type="text"
                  :placeholder="$t('interview.userInfo.email.input')"
                  v-model="interview.email"
                />
              </validation-provider>
            </div>
            <div
              v-if="interview.phone || interview.email"
              class="interview__item"
            >
              <p v-if="isPrivacyValidate">
                {{ $t("interview.userInfo.privacy.validate") }}
              </p>
              <div
                v-if="$i18n.locale === 'ru'"
                class="interview__item-checkbox"
              >
                <input
                  class="interview__item-check"
                  type="checkbox"
                  id="Privacy"
                  v-model="isPrivacy"
                />
                <label for="Privacy">
                  Я согласен с условиями
                  <a
                    class="interview__item-link"
                    :href="getPrivacy"
                    target="_blank"
                  >
                    Политики конфиденциальности.
                  </a>
                </label>
              </div>
              <div v-else class="interview__item-checkbox">
                <input
                  class="interview__item-check"
                  type="checkbox"
                  id="Privacy"
                  v-model="isPrivacy"
                />
                <label for="Privacy">
                  Мен
                  <a
                    class="interview__item-link"
                    :href="getPrivacy"
                    target="_black"
                  >
                    құпиялылық саясатының шарттарымен</a
                  >
                  келісемін.
                </label>
              </div>
            </div>
            <button @click.prevent="sendInterview" class="interview__btn">
              {{ $t("send") }}
            </button>
          </form>
        </validation-observer>
      </div>
    </div>
    <app-modal-received
      v-if="isShowModal"
      @close="isShowModal = false"
      title="interviewAppeal"
      image="donute.svg"
      btn="goodAppeal"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { getAllCategories } from "@/api/category";
import { actionTypes } from "@/store/modules/catalog";
import { getShopsByFilters } from "@/api/shop";
import { sendSurveys } from "@/api/cx-surveys";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  name: "Interview",
  components: {
    ValidationProvider,
    ValidationObserver,
    AppModalReceived: () => import("@/components/modal/AppModalReceived.vue"),
  },
  data() {
    return {
      checkboxes: [],
      shopsForApi: [],
      shopsData: [],
      isShowCities: false,
      isShowShops: false,
      isShowPrivacy: false,
      selectCity: null,
      shopAddress: "",
      interview: {
        sku_name: "",
        cities: null,
        shops: null,
        sku_description: "",
        categories: [],
        other: "",
        phone: "",
        email: "",
      },
      isPrivacy: false,
      showOther: false,
      // vacancy
      isPrivacyValidate: false,
      isCityValidate: false,
      isShopsValidate: false,
      isCategoriesValidate: false,
      isShowModal: false,

      isShopsLoader: true,
    };
  },
  computed: {
    ...mapState("catalogModule", ["categories"]),
    ...mapState("cityModule", { cities: "data", selectedCity: "selectedCity" }),
    getPrivacy() {
      return (
        process.env.VUE_APP_API_URL +
        "/uploads/Politika_konfdenczialnosti_po_oprosu_c3d3c9e934.pdf?updated_at=2022-11-02T11:39:20.222Z"
      );
    },
  },
  created() {
    getShopsByFilters().then((shops) => {
      this.shopsForApi = shops;
      this.getShops();
      this.isShopsLoader = false;
    });
  },
  mounted() {
    getAllCategories().then((categories) => {
      this.checkboxes = categories;
      this.getCategories(categories);
    });
    document?.addEventListener("click", () => {
      this.isShowCities = false;
      this.isShowShops = false;
    });
  },
  watch: {
    shopAddress: {
      handler() {
        this.getShops();
      },
    },
    selectCity: {
      handler() {
        this.isShopsLoader = true;
        getShopsByFilters(this.selectCity.id).then((shops) => {
          this.shopsForApi = shops;
          this.getShops();
          this.isShopsLoader = false;
        });
      },
    },
  },
  methods: {
    ...mapActions("catalogModule", {
      getCategories: actionTypes.getCategories,
    }),
    showDropdown(e) {
      e.target.nextElementSibling.classList.toggle("is-show");
    },
    getSelectCity(city) {
      this.selectCity = city;
      this.interview.cities = city.id;
      this.isShowCities = false;
    },
    selectShop(shop) {
      this.shopAddress =
        shop.attributes.name +
        " " +
        shop.attributes.shop_type?.data?.attributes?.name +
        " " +
        shop.attributes.address;
      this.interview.shops = shop.id;
      this.isShowShops = false;
    },
    selectCategories(id) {
      if (!this.interview.categories.includes(id)) {
        this.interview.categories.push(id);
      } else {
        this.interview.categories.splice(
          this.interview.categories.indexOf(id),
          1
        );
      }
    },
    getShops() {
      if (this.shopAddress) {
        this.shopsData = this.shopsForApi.filter((e) =>
          e.attributes.address
            .toLowerCase()
            .includes(this.shopAddress.toLowerCase())
        );
      } else {
        this.shopsData = this.shopsForApi;
      }
    },
    sendInterview() {
      // переписать
      if (!this.interview.cities) {
        this.isCityValidate = true;
      } else {
        this.isCityValidate = false;
      }
      if (!this.interview.shops) {
        this.isShopsValidate = true;
      } else {
        this.isShopsValidate = false;
      }
      if (
        this.interview.categories.length
          ? !this.interview.categories.length
          : !this.interview.other
      ) {
        this.isCategoriesValidate = true;
      } else {
        this.isCategoriesValidate = false;
      }
      if (
        !this.interview.cities &&
        !this.interview.shops &&
        (this.interview.categories.length
          ? !this.interview.categories.length
          : !this.interview.other)
      ) {
        window.scrollTo(0, 0);
      } else {
        if ((this.interview.phone || this.interview.email) && !this.isPrivacy) {
          this.isPrivacyValidate = true;
        } else {
          this.isPrivacyValidate = false;
          this.$refs.formInterview.validate().then((success) => {
            if (success) {
              sendSurveys({ data: { ...this.interview } }).then(() => {
                this.$nextTick(() => {
                  this.$refs.formInterview.reset();
                });
                this.isShowModal = true;
                this.interview = {
                  sku_name: "",
                  cities: null,
                  shops: null,
                  sku_description: "",
                  categories: [],
                  other: "",
                  phone: "",
                  email: "",
                };
                this.shopAddress = "";
                this.selectCity = null;
                this.isShowValidate = false;
                this.isBtnDisabled = false;
                this.isPrivacyValidate = false;
                this.isCityValidate = false;
                this.isShopsValidate = false;
                this.isCategoriesValidate = false;
                this.showOther = false;
              });
            } else {
              window.scrollTo(0, 0);
            }
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
